import type { AccountInfo } from '@azure/msal-browser-1p';
import { getTypeHint } from '../getTypeHint';
import { AccountSourceType } from 'owa-account-source-list-types';
import { getIdleSessionTimeout, WSIT_COOKIE_KEY } from 'owa-session-store';
import { isHosted } from 'owa-config';

export function setIdleSessionCookieIfEnabled(account: AccountInfo): void {
    const cookieValue = getIdleSessionTimeout();
    const xms_wsit = account?.idTokenClaims?.['xms_wsit'] ?? undefined;

    if (
        !isHosted() &&
        getTypeHint() === AccountSourceType.Office365 &&
        xms_wsit !== undefined &&
        !cookieValue
    ) {
        self.document.cookie = `${WSIT_COOKIE_KEY}=${xms_wsit}; path=/; SameSite=None; Secure`;
    }
}
